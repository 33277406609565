const defaultOptions = {
  theme: 'material',
  backgroundColor: {
    fill: '#ffffff',
    fillOpacity: .5
  },
};

const dailySalesChart = {
  data: [
    ['x', 'Total'],
    ['Monday', 10],
    [1, 20],
    [2, 30],
    [3, 30],
    [4, 10],
    [5, 50]
  ],
   options: Object.assign({},defaultOptions,{
    series: {
      1: {color: '#5E97F6', lineDashStyle: [4,4]}
    },
    legend: { position: 'none' },
    vAxis: {
      baseline: 0
    },
    focusTarget: 'category',
  }),
};

const platformPieChart = {
  data: {
    labels: ['Android', 'iOS', 'Desktop'],
    series: [1, 2, 3]
  },
  options: Object.assign({},defaultOptions,{
    // legend: { position: 'left' },
    // pieSliceText: 'percentage',
    sliceVisibilityThreshold: 0.01,
  }),
};

const geoChart = {
   options: Object.assign({},defaultOptions,{
    // datalessRegionColor: '#555',
    colorAxis: {colors: ['#d7e2f4', '#5E97F6']}
  }),
};

module.exports = {
  dailySalesChart,
  platformPieChart,
  geoChart
};