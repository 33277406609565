import facebookIcon from "assets/icon/social/facebook.png";
import instagramIcon from "assets/icon/social/instagram.png";
import messengerIcon from "assets/icon/social/messenger.png";
import questionIcon from "assets/icon/social/question.png";
import snapchatIcon from "assets/icon/social/snapchat.png";
import telegramIcon from "assets/icon/social/telegram.png";
import twitterIcon from "assets/icon/social/twitter.png";
import youtubeIcon from "assets/icon/social/youtube.png";

const validateURL = val => {
	// let regexface = '/^(https?:\/\/)?(www\.)?facebook.com\/[a-zA-Z0-9(\.\?)?]/g';
    let regexTritter = new RegExp("^(https?://)?(www.)?twitter.com/[a-zA-Z0-9(.?)?]");
    let regexInsta = new RegExp("^(https?://)?(www.)?instagram.com/[a-zA-Z0-9(.?)?]");
    let regexface = new RegExp("^(https?://)?(www.)?facebook.com/[a-zA-Z0-9(.?)?]");
    let regexyoutube = new RegExp("^(https?://)?(www.)?youtube.com/[a-zA-Z0-9(.?)?]");
    let regexwapp = new RegExp("^(https?://)?(www.)?wa.me/[a-zA-Z0-9(.?)?]");
    let regexsnapchat = new RegExp("^(https?://)?(www.)?snapchat.com/[a-zA-Z0-9(.?)?]");
    let regextelegram = new RegExp("^(https?://)?(www.)?telegram.com/[a-zA-Z0-9(.?)?]");

    if (regexTritter.test(val)) {
    	return "twitter";
    } else if(regexInsta.test(val)) {
    	return "instagram";
    }else if(regexface.test(val)) {
    	return "facebook";
    }else if(regexyoutube.test(val)) {
		return "youtube";
    }else if(regexwapp.test(val)) {
    	return "whatsapp";
    }else if(regexsnapchat.test(val)) {
    	return "snapchat";
    }else if(regextelegram.test(val)) {
    	return "telegram";
    }else{
    	let pattern = new RegExp('^(https?:\\/\\/)?'+ 
		    '((([a-z\\d]([a-z\\d-]*[a-z\\d])*)\\.)+[a-z]{2,}|'+
		    '((\\d{1,3}\\.){3}\\d{1,3}))'+
		    '(\\:\\d+)?(\\/[-a-z\\d%_.~+]*)*'+
		    '(\\?[;&a-z\\d%_.~+=-]*)?'+ 
		    '(\\#[-a-z\\d_]*)?$','i');
		
		let res =  !!pattern.test(val);
		if (res) {
			return "qa";
		} else {
    		return "";
		}
    }
};


export const iconActiveClass = link => {
	let linkType =  validateURL(link);
	if(linkType === "twitter"){
		return "twIcon";
	}else if(linkType === "instagram"){
		return "instaIcon";
	}else if(linkType === "facebook"){
		return "fbIcon";
	}else if(linkType === "youtube"){
		return "ytIcon";
	}else if(linkType === "whatsapp"){
		return "mesIcon";
	}else if(linkType === "snapchat"){
		return "snapcIcon";
	}else if(linkType === "telegram"){
		return "telegIcon";
	}else{
		return "quIcon";	
	}
};
export const iconImage = link =>{
	let linkType =  validateURL(link);
	if(linkType === "twitter"){
		return twitterIcon;
	}else if(linkType === "instagram"){
		return instagramIcon;
	}else if(linkType === "facebook"){
		return facebookIcon;
	}else if(linkType === "youtube"){
		return youtubeIcon;
	}else if(linkType === "whatsapp"){
		return messengerIcon;
	}else if(linkType === "snapchat"){
		return snapchatIcon;
	}else if(linkType === "telegram"){
		return telegramIcon;
	}else{
		return questionIcon	
	}
};
export  default validateURL;