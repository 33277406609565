import React, { useState } from "react";
// @material-ui/core components
import { FormControl, FormLabel, FormControlLabel, RadioGroup, Radio } from '@material-ui/core';
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CheckoutForm from 'components/CheckoutForm/CheckoutForm.js';

import { Elements } from '@stripe/react-stripe-js';
import { loadStripe } from '@stripe/stripe-js';
import stripeApi from 'services/payments.js';

import { useAuth0 } from '../../react-auth0-spa.js';

const stripePromise = stripeApi.getPublicStripeKey().then(key => loadStripe(key));

export default function Settings() {
  const { isAuthenticated, loginWithRedirect, logout } = useAuth0();

  const [planIndex, setPlanIndex] = useState('0');
  const [notification, setNotification] = useState('');

  const updatePlan = async event => {
    event.preventDefault();

    const customer = await stripeApi.getCustomer();

    if (!customer.default_source && !customer.invoice_settings.default_payment_method) {
      setNotification('Please add a billing method!');
      setTimeout(()=>setNotification(''), 2000);
    } else {
      await stripeApi.subscribe({ planIndex: +planIndex });
    }

  };

  return (
    <Elements stripe={stripePromise}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
            <CardBody>
            <h6>Your plan</h6>
            <span style={{color: 'red'}}>{notification}</span>
            <FormControl component="fieldset" className="planWrap">
              
              <RadioGroup aria-label="plan" name="plan1" value={planIndex} onChange={(e,newValue)=>setPlanIndex(newValue)}>
              <ul className="radiWrap">
                <li className={planIndex == 0 ? "active" : ""}>
                  <FormControlLabel value="0" control={<Radio />} label="Free" />
                  <div className="borderWrap">
                    <span>$0</span>
                  </div>
                </li>
                <li className={planIndex == 1 ? "active" : ""}>
                  <FormControlLabel value="1" control={<Radio />} label="Basic" />
                  <div className="borderWrap">
                    <span>$9,99</span>
                  </div>
                </li>
                <li className={planIndex == 2 ? "active" : ""}>
                  <FormControlLabel value="2" control={<Radio />} label="Premium" />
                  <div className="borderWrap">
                    <span>$24,99</span>
                  </div>
                </li>
                </ul>
              </RadioGroup>
              <Button onClick={updatePlan} disabled>Update Plan</Button>
            </FormControl>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
        <Card className="minHeight-382">
            <CardBody>
            <h6>Creditcard</h6>
            <span style={{color: 'red'}}>{notification}</span>
              {isAuthenticated ? <CheckoutForm planIndex={+planIndex}/> : null}
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </Elements>
  );
}