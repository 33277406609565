import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import Toolbar from '@material-ui/core/Toolbar';
import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import Hidden from '@material-ui/core/Hidden';

import { Menu as MenuIcon } from '@material-ui/icons';

import logo from "assets/img/logo.png";

import { useAuth0 } from "../../react-auth0-spa";

const useStyles = makeStyles((theme) => ({
  logo: {
    width: '157px'
  },
  logoContainer: {
    flex: 1,
  },
  menu: {
    textAlign: 'center',
  },
  menuItem: {
    color: '#fff'
  },
  menuButton: {
    color: '#fff'
  },
  loginButton: {
    color: '#fff'
  },
  popoverItem: {
    color: theme.palette.primary.main,
    margin: 'auto',
    padding: '0rem 1rem'
  }
}));

export default function LanderAppBar(props) {
  const { loginWithRedirect } = useAuth0();
  const classes = useStyles();

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const handleMenu = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClick = (event) => {
    setAnchorEl(null);
  };

  const menuItems = (popover = false) => (<React.Fragment>
                      <MenuItem component={Button} href='#features' onClick={handleClick} className={popover ? classes.popoverItem : classes.menuItem}>Features</MenuItem>
                      <MenuItem component={Button} href='#pricing' onClick={handleClick} className={popover ? classes.popoverItem : classes.menuItem}>Pricing</MenuItem>
                      <MenuItem component={Button} variant='outlined' onClick={loginWithRedirect} className={popover ? classes.popoverItem : classes.loginButton}>Login</MenuItem>
                    </React.Fragment>);

  return (
    <Toolbar className='smooth-scroll'>
        <div className={classes.logoContainer}>
          <img src={logo} className={classes.logo} alt='deepy-logo'/>
        </div>
        <Hidden smUp> 
          <IconButton
            onClick={handleMenu}
            color="inherit"
            edge='end'
          >
            <MenuIcon className={classes.menuButton}/>
          </IconButton>
          <Menu
            id="menu-appbar"
            anchorEl={anchorEl}
            anchorOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            keepMounted
            transformOrigin={{
              vertical: 'top',
              horizontal: 'right',
            }}
            open={open}
            onClose={handleClick}
            className={classes.menu}
          >
            {menuItems(true)}
          </Menu>
        </Hidden>
        <Hidden only='xs'>
          {menuItems()}
        </Hidden>
      </Toolbar>
  );
};