import React from "react";
// @material-ui/core

import { makeStyles, createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';

import {Container} from "@material-ui/core";

import {Redirect} from 'react-router-dom';

import Pricing from 'components/Pricing/Pricing.js';
import LanderHero from 'components/LanderHero/LanderHero.js';
import LanderFeatures from 'components/LanderFeatures/LanderFeatures.js';
import LanderAppBar from 'components/LanderAppBar/LanderAppBar.js';
import LanderFooter from 'components/LanderFooter/LanderFooter.js';

import MuiThemeOverride from 'assets/jss/themeOverride.js';


import {useAuth0} from '../../react-auth0-spa.js';

const landerTheme = responsiveFontSizes(createMuiTheme({
  ...MuiThemeOverride,
	palette: {
		text: {
      primary: '#fff',
      secondary: 'rgba(255,255,255,0.7)'
    }
	}
}));

const useStyles = makeStyles((theme) => ({
  container: {
    [theme.breakpoints.down('sm')]: {
      paddingLeft: 0,
      paddingRight: 0
    }
  }
}));

export default function Creator() {
  const { isAuthenticated } = useAuth0();

  const classes = useStyles();

  return (
      <MuiThemeProvider theme={landerTheme}>
        <Container maxWidth='lg' className={classes.container}>
        	{isAuthenticated ? <Redirect to='/dashboard' /> : null}
        	<LanderAppBar />
        	<LanderHero />
        	<LanderFeatures />
          <Pricing />
          <LanderFooter />
        </Container>
      </MuiThemeProvider>
  );
}