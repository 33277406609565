import React, { useState, useEffect } from "react";

import moment from 'moment';
import _ from 'lodash';
// react plugin for creating charts
import Chart from 'react-google-charts';
// @material-ui/core
import { makeStyles } from "@material-ui/core/styles";
import { Icon, Divider, Select, MenuItem } from "@material-ui/core";
// @material-ui/icons
import Accessibility from "@material-ui/icons/Accessibility";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Table from "components/Table/Table.js";
import Card from "components/Card/Card.js";
import CardHeader from "components/Card/CardHeader.js";
import CardIcon from "components/Card/CardIcon.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";

import {
  dailySalesChart,
  platformPieChart,
  geoChart
} from "variables/charts.js";

import linksService from 'services/links.js';

import styles from "assets/jss/material-dashboard-react/views/dashboardStyle.js";

const useStyles = makeStyles(styles);



const dateFromObjectId = function(objectId) {
  return new Date(parseInt(objectId.substring(0, 8), 16) * 1000);
};

export default function DetailPanel({ linkData }) {
  const classes = useStyles();
  const [now, setNow] = useState(+moment());

  const [totalClicks, setTotalClicks] = useState(0);
  const [dailyClicks, setDailyClicks] = useState(0);

  const [timeRangeIndex, setTimeRangeIndex] = useState(0);
  const [timeRange, setTimeRange] = useState([+moment(now).subtract(1, 'day'), +moment(now).add(1, 'day').startOf('day')]);
  const [timeRangeValues, setTimeRangeValues] = useState([
    [+moment(now).startOf('day'), +moment(now).endOf('day')]
  ]);
  const [uniqueVisitors, setUniqueVisitors] = useState(0);
  const [platformData, setPlatformData] = useState([
    ['OS', 'Clicks']
  ]);
  const [browserData, setBrowserData] = useState([
    ['Browser', 'Clicks']
  ]);
  const [referrerData, setReferrerData] = useState([
    ['Referrer', 'Clicks']
  ]);
  const [timeClicks, setTimeClicks] = useState([]);
  const [graphClickData, setGraphClickData] = useState([
    ['Day', 'Total'],
    [0, 0]
  ]);
  const [geoClickData, setGeoClickData] = useState([
    ['Country', 'Clicks'],
    ['United States', 0]
  ]);

  const updateGraphClickData = (getClicks, numTimeUnits = 7, timeUnit = 'day') => {
    setGraphClickData([
      [timeUnit, 'Current', 'Previous'],
      ..._.range(~~numTimeUnits, -1).map(timeUnitsAgo => [`${moment(timeRange[1]).subtract(timeUnitsAgo, timeUnit).format(timeUnit === 'day' ? 'dd M/D' : 'hA M/D')} vs ${moment(timeRange[0]).subtract(timeUnitsAgo, timeUnit).format(timeUnit === 'day' ? 'dd M/D' : 'hA M/D')} `,
        getClicks(timeUnitsAgo, timeUnit),
        getClicks(timeUnitsAgo, timeUnit, true)
      ])
    ]);
  };

  const handleTimeRangeSelectChange = event => {
    setTimeRangeIndex(event.target.value);
    setTimeRange(timeRangeValues[event.target.value]);
    const [fromTime, toTime] = timeRangeValues[event.target.value];
    const extractTimeClicks = (daysAgo, timePeriod = 'day', beforeStart = false) =>
      timeClicks.filter(e => moment(e.timestamp).isSame(moment(timeRangeValues[event.target.value][beforeStart ? 0 : 1]).subtract(daysAgo, timePeriod), timePeriod)).length;
    const selectedDuration = moment.duration(moment(toTime).diff(moment(fromTime)));
    
    if (selectedDuration.asDays() > 1) {
      updateGraphClickData(extractTimeClicks, selectedDuration.asDays());
    } else if (selectedDuration.asHours() >= 1) {
      updateGraphClickData(extractTimeClicks, selectedDuration.asHours(), 'hour');
    }
  };

  useEffect(() => {
    async function fetchData() {
      const link = await linksService.getLinkDetails(linkData.id);
      const tempTimeClicks = link.clickData.map(e => ({ ...e, timestamp: dateFromObjectId(e._id) }));
      setTimeClicks(tempTimeClicks);
      const extractClicks = (daysAgo, timePeriod = 'day') => tempTimeClicks.filter(e => moment(e.timestamp).isSame(moment(now).subtract(daysAgo, timePeriod), timePeriod)).length
      
      
      
      setTotalClicks(link.clicks);
      /* following should be refactored with ramda or lodash or underscore or something dear god please */
      setDailyClicks(extractClicks(0));
      const platformEntries = Object.entries(_.countBy(_.filter(link.clickData, 'platform'), 'platform'));
      setPlatformData([
        ['Operating System', 'Clicks'],
        ...platformEntries
      ]);
      const browserEntries = Object.entries(_.countBy(_.filter(link.clickData, 'browser'), 'browser'));
      setBrowserData([
        ['Browser', 'Clicks'],
        ...browserEntries
      ]);
      const referrerEntries = Object.entries(_.countBy(_.filter(link.clickData, 'referrer'), 'referrer'));
      setReferrerData([
        ['Referrer', 'Clicks'],
        ...referrerEntries
      ]);
      
      
      const geoEntries = Object.entries(_.countBy(link.clickData, 'country')).filter(e => e[0] !== 'undefined');
      
      setGeoClickData([
        ['Country', 'Clicks'],
        ...geoEntries
      ]);
      setUniqueVisitors(Object.keys(_.countBy(link.clickData, 'ip')).length)
      setTimeRangeValues([
        [+moment(now).startOf('day'), +moment(now).add(1, 'day').startOf('day')],
        [+moment(now).subtract(1, 'day').startOf('day'), +moment(now).startOf('day')],
        [+moment(now).subtract(7, 'day'), +moment(now).endOf('day')],
      ]);
      handleTimeRangeSelectChange({ target: { value: 0 } });
      const tempTimeRange = [+moment(now).startOf('day'), +moment(now).add(1, 'day').startOf('day')];
      setGraphClickData([
        ['hour', 'Current', 'Previous'],
        ..._.range(~~24, -1).map(timeUnitsAgo => [`${moment(tempTimeRange[0]).subtract(timeUnitsAgo, 'hour').format('hA M/D')} vs ${moment(tempTimeRange[1]).subtract(timeUnitsAgo, 'hour').format('hA M/D')} `,
          tempTimeClicks.filter(e => moment(e.timestamp).isSame(moment(tempTimeRange[1]).subtract(timeUnitsAgo, 'hour'), 'hour')).length,
          tempTimeClicks.filter(e => moment(e.timestamp).isSame(moment(tempTimeRange[0]).subtract(timeUnitsAgo, 'hour'), 'hour')).length,
        ])
      ]);
    }
    fetchData();
  }, []);

  return (
    <div>
      <GridContainer>
        <GridItem xs={12} sm={4} md={2}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <Icon>touch_app</Icon>
              </CardIcon>
              <p className={classes.cardCategory}>Total Clicks</p>
              <h3 className={classes.cardTitle}>
                {totalClicks}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={6} sm={4} md={2}>
          <Card>
            <CardHeader color="primary" stats icon>
              <CardIcon color="primary">
                <Accessibility />
              </CardIcon>
              <p className={classes.cardCategory}>Visitors</p>
              <h3 className={classes.cardTitle}>
                {uniqueVisitors}
              </h3>
            </CardHeader>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card chart>
            <CardHeader color="success">
              <Chart
                className="ct-chart"
                chartType="LineChart"
                data={graphClickData}
                options={dailySalesChart.options}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Daily Clicks</h4>
              <p className={classes.cardCategory}>
              {dailyClicks} today
              </p>
            </CardBody>
            <Divider/>
            <CardFooter>
              <Select value={timeRangeIndex} onChange={handleTimeRangeSelectChange}>
                <MenuItem value={0}>Today</MenuItem>
                <MenuItem value={1}>Yesterday</MenuItem>
                <MenuItem value={2}>Last 7 Days</MenuItem>
              </Select>
            </CardFooter>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={4}>
          <Card chart>
            <CardHeader color="success">
              <Chart
                data={platformData}
                chartType="PieChart"
                options={platformPieChart.options}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Platform Distribution</h4>
              <p className={classes.cardCategory}>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card chart>
            <CardHeader color="success">
              <Chart
                data={browserData}
                chartType="PieChart"
                options={platformPieChart.options}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Browser Distribution</h4>
              <p className={classes.cardCategory}>
              </p>
            </CardBody>
          </Card>
        </GridItem>
        <GridItem xs={12} sm={6} md={3}>
          <Card chart>
            <CardHeader color="success">
              <Chart
                data={referrerData}
                chartType="PieChart"
                options={platformPieChart.options}
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Referrer Distribution</h4>
              <p className={classes.cardCategory}>
              </p>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
      <GridContainer>
        <GridItem xs={12} sm={12} md={12}>
          <Card chart>
            <CardHeader color="success">
              <Chart
                chartType="GeoChart"
                data={geoClickData}
                options={geoChart.options}
                mapsApiKey="AIzaSyC0AVOZ8gQ7d2meHvuDBtl7gcE03xv0e-Q"
              />
            </CardHeader>
            <CardBody>
              <h4 className={classes.cardTitle}>Location Data</h4>
            </CardBody>
          </Card>
        </GridItem>
      </GridContainer>
    </div>
  );
}