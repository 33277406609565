import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Container from "@material-ui/core/Container";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import {useAuth0} from '../../react-auth0-spa.js';

// Material Icons
// @material-ui/icons

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8),
    marginBottom: theme.spacing(14),
    textAlign: 'center'
  },
  title: {
    fontWeight: 700,
    lineHeight: '1.125',
    fontSize: '1.8rem',
    marginTop: theme.spacing(2),
    marginBottom: theme.spacing(5),
    [theme.breakpoints.down('sm')]: {
        fontSize: '2rem'
    }
  },
  subtitle: {
    margin: theme.spacing(1),
    marginBottom: theme.spacing(2)
  },
  button: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    }
  },
  item: {
    display: 'flex',
    flexDirection: 'column',
    alignItems: 'center',
    padding: theme.spacing(0),
  },
  icon: {
    fontSize: 64,
    color: '#fff'
  },
  bodyText: {
    textAlign: 'center'
  }
}));

export default function LanderFooter(props) {
  const { loginWithRedirect } = useAuth0();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      {/* Increase the network loading priority of the background image. */}
      <Typography align="center" variant="h2" color="textPrimary" className={classes.title}>
        Try Deepy today for free!
      </Typography>
      <Typography align="center" variant="h5" className={classes.subtitle} color="textSecondary">
        We're confident you'll gain more followers, close more sales, and convert more traffic through social media. Finally get the conversion rate and growth you deserve!
      </Typography>
      <Button
        style={{color: '#5411db', backgroundColor: '#fff'}}
        variant="contained"
        size="large"
        className={classes.button}
        onClick={() => loginWithRedirect({screen_hint: 'signup', prompt: 'login'})}
      >
        Start a Free Trial
      </Button>
    </Container>
  );
};