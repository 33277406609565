import React from "react";
import { Switch, Route } from "react-router-dom";
// creates a beautiful scrollbar
import PerfectScrollbar from "perfect-scrollbar";
import "perfect-scrollbar/css/perfect-scrollbar.css";
// @material-ui/core components
import { makeStyles } from "@material-ui/core/styles";
// core components
import DashboardHedr from "components/Header/DashboardHedr.js";
import AdminNavbarLinks from "components/Navbars/AdminNavbarLinks.js";

import routes from "routes.js";

import styles from "assets/jss/material-dashboard-react/layouts/adminStyle.js";

import {useAuth0} from '../react-auth0-spa.js';

import logo from "assets/img/logo.png";
import { red } from "@material-ui/core/colors";

let ps;


const useStyles = makeStyles(styles);

export default function Admin({ ...rest }) {
  const {isAuthenticated, user} = useAuth0();
  // styles
  const classes = useStyles();
  // ref to help us initialize PerfectScrollbar on windows devices
  const mainPanel = React.createRef();
  // states and functions
  const [mobileOpen, setMobileOpen] = React.useState(false);


  const switchRoutes = (
    <Switch>
      <Route exact path='/' component={routes[0].component}/>
      {routes.filter(e=>!e.spacer).map((prop, key) => {
          return (
            <Route
              path={prop.layout + prop.path}
              component={prop.component}
              key={key}
            />
          );
      })}
    </Switch>
  );

  // initialize and destroy the PerfectScrollbar plugin
  React.useEffect(() => {
    if (navigator.platform.indexOf("Win") > -1) {
      ps = new PerfectScrollbar(mainPanel.current, {
        suppressScrollX: true,
        suppressScrollY: false
      });
      document.body.style.overflow = "hidden";
    }
    // Specify how to clean up after this effect:
    return function cleanup() {
      if (navigator.platform.indexOf("Win") > -1) {
        ps.destroy();
      }
    };
  }, [mainPanel]);
  return (
    <div className={classes.wrapper}>
      {isAuthenticated ? 
      <DashboardHedr
        routes={routes}
        logoText={"Deeplink App"}
        logo={logo}
      /> : null }
      <div className={classes.mainPanel} ref={mainPanel} style={isAuthenticated ? {background:'#F5F3F9'} : {width: '100%'}}>
          <div className={classes.content}>
            <div className={classes.container}>{switchRoutes}</div>
          </div>
      </div>
    </div>
  );
}