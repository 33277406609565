import React from "react";
import ReactDOM from "react-dom";
import { Router, Route } from "react-router-dom";

import { ConfirmProvider } from 'material-ui-confirm';

// core components
import Admin from "layouts/Admin.js";
import "assets/css/material-dashboard-react.css?v=1.8.0";
import { createMuiTheme, MuiThemeProvider, responsiveFontSizes } from '@material-ui/core/styles';
import MuiThemeOverride from 'assets/jss/themeOverride.js';

import { Auth0Provider } from "./react-auth0-spa.js";
import config from "./auth_config.json";
import history from "./utils/history.js";



// A function that routes the user to the right place
// after login
const onRedirectCallback = appState => {
  history.push(
    appState && appState.targetUrl ?
    appState.targetUrl :
    window.location.pathname
  );
};

const theme = responsiveFontSizes(createMuiTheme(MuiThemeOverride));


ReactDOM.render(
  <Auth0Provider
    domain={config.domain}
    client_id={config.clientId}
    audience={config.audience}
    redirect_uri={window.location.origin}
    onRedirectCallback={onRedirectCallback}
  >
  <MuiThemeProvider theme={theme}>
            <ConfirmProvider>
  <Router history={history}>
      <Route path="/" component={Admin} />
  </Router>
            </ConfirmProvider>
  </MuiThemeProvider>
  </Auth0Provider>,
  document.getElementById("root")
);