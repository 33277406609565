import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Grid from '@material-ui/core/Grid';
import Container from "@material-ui/core/Container";
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import { useAuth0 } from "../../react-auth0-spa";

import LanderHeroImg from 'assets/img/LanderHeroImg.png';

const useStyles = makeStyles((theme) => ({
  container: {
    marginTop: theme.spacing(8), 
    marginBottom: theme.spacing(14),
    height: 'auto',
    [theme.breakpoints.down('sm')]: {
        marginTop: theme.spacing(3),
    }
  },
  background: {
    position: 'fixed',
    left: 0,
    right: 0,
    top: 0,
    // bottom: 0,
    height: '100%',
    backgroundSize: 'cover',
    backgroundRepeat: 'no-repeat',
    zIndex: -2,
    backgroundImage: `linear-gradient(#5411db, #6b21d8)`,
  },
  heroText: {
    paddingTop: theme.spacing(4),
    paddingRight: theme.spacing(4),
    [theme.breakpoints.down('xs')]: {
      paddingTop: theme.spacing(0),
        paddingRight: theme.spacing(0)
    },
    [theme.breakpoints.up('lg')]: {
      paddingTop: theme.spacing(14),
    }
  },
  title: {
    fontWeight: 700,
    lineHeight: '1.125',
    fontSize: '1.8rem',
    [theme.breakpoints.down('sm')]: {
        fontSize: '2rem'
    }
  },
  subtitle: {
    margin: theme.spacing(1),
    [theme.breakpoints.down('sm')]: {
        fontSize: '1rem'
    }
  },
  gridContainer: {
    textAlign: 'center'
  },
  button: {
    margin: theme.spacing(3),
    [theme.breakpoints.down('sm')]: {
      width: '80%',
    }
  },
  img: {
    width: '100%',
    maxWidth: '320px',
    [theme.breakpoints.up('lg')]: {
      paddingLeft: theme.spacing(8),
    }
  }
}));

export default function LanderHero(props) {
  const {loginWithRedirect } = useAuth0();
  const classes = useStyles();

  return (
    <Container className={classes.container}>
      <div className={classes.background} />
      {/* Increase the network loading priority of the background image. */}
      <Grid container className={classes.gridContainer}>
        <Grid item xs={12} sm={6} className={classes.heroText}>
          <Typography variant="h2" color="textPrimary" className={classes.title}>
            Deep Linking For Social Media Apps
          </Typography>
          <Typography variant="h5" color="textSecondary" className={classes.subtitle}>
            Convert more mobile traffic on Facebook, Instagram, Youtube, and more by redirecting links directly into social media apps.
          </Typography>
          <Button
            style={{color: '#5411db', backgroundColor: '#fff'}}
            variant="contained"
            size="large"
            className={classes.button}
            onClick={() => loginWithRedirect({screen_hint: 'signup', prompt: 'login'})}
          >
            Start a Free Trial
          </Button>
        </Grid>
        <Grid item xs={12} sm={6}>
          <img src={LanderHeroImg} className={classes.img} alt='iphone-hero-img'/>
        </Grid>
      </Grid>
    </Container>
  );
};