import React from "react";
// @material-ui/core components
import {
  fade,
  withStyles,
} from '@material-ui/core/styles';
import { makeStyles } from "@material-ui/core/styles";
// core components
import GridItem from "components/Grid/GridItem.js";
import GridContainer from "components/Grid/GridContainer.js";
import Container from "@material-ui/core/Container";
import FormControl from '@material-ui/core/FormControl';
import InputLabel from '@material-ui/core/InputLabel';
import InputBase from '@material-ui/core/InputBase';

// import CustomInput from "components/CustomInput/CustomInput.js";
import Button from "components/CustomButtons/Button.js";
import Card from "components/Card/Card.js";
// import CardHeader from "components/Card/CardHeader.js";
import CardBody from "components/Card/CardBody.js";
import CardFooter from "components/Card/CardFooter.js";
import Settings from "views/Settings/Settings.js";
import logoutIcon from "assets/icon/logout.png";
import lockIcon from "assets/icon/lock.png";

const styles = {
  cardCategoryWhite: {
    color: "rgba(255,255,255,.62)",
    margin: "0",
    fontSize: "14px",
    marginTop: "0",
    marginBottom: "0"
  },
  cardTitleWhite: {
    color: "#FFFFFF",
    marginTop: "0px",
    minHeight: "auto",
    fontWeight: "300",
    fontFamily: "'Roboto', 'Helvetica', 'Arial', sans-serif",
    marginBottom: "3px",
    textDecoration: "none"
  }
};
const BootstrapInput = withStyles((theme) => ({
  root: {
    'label + &': {
      marginTop: theme.spacing(3),
    },
  },
  input: {
    borderRadius: 4,
    position: 'relative',
    backgroundColor: theme.palette.common.white,
    border: '1px solid #ced4da',
    fontSize: 15,
    minHeight: 48,
    boxSizing: 'border-box',
    width: '100%',
    padding: '10px 12px',
    transition: theme.transitions.create(['border-color', 'box-shadow']),
    '&:focus': {
      boxShadow: `${fade(theme.palette.primary.main, 0.25)} 0 0 0 0.2rem`,
      borderColor: theme.palette.primary.main,
    },
  },
  Button: {
    fontSize: 15,
  },
}))(InputBase);
const useStyles = makeStyles(styles);

export default function Accounts() {
  const classes = useStyles();
  return (
    <div className="accountwrap">
    <Container className={classes.container}>
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <h1>Account</h1>
        </GridItem>
        <GridItem xs={12} sm={6} md={6}>
          <ul className="inline-buttons">
            <li>
              <a href="/"><img src={lockIcon} alt="icon" /> Change password</a>
            </li>
            <li>
              <a href="/"><img src={logoutIcon} alt="icon" /> Log out</a>
            </li>
          </ul>
        </GridItem>
      </GridContainer>
      <Settings />
      <GridContainer>
        <GridItem xs={12} sm={6} md={6}>
          <Card>
           <CardBody>
             <h6>Details</h6>
             <FormControl className="form-control">
                <InputLabel shrink htmlFor="firstname">
                First name
                </InputLabel>
                <BootstrapInput value="0" id="firstname"/>
              </FormControl>
              <FormControl className="form-control">
                <InputLabel shrink htmlFor="lasttname">
                Last NAME
                </InputLabel>
                <BootstrapInput value="0" id="lasttname"/>
              </FormControl>
              <FormControl className="form-control">
                <InputLabel shrink htmlFor="email-input">
                Email
                </InputLabel>
                <BootstrapInput value="0" id="email-input"/>
              </FormControl>
              <FormControl className="form-control">
                <InputLabel shrink htmlFor="company-input">
                COMPANY NAME
                </InputLabel>
                <BootstrapInput value="0" id="company-input"/>
              </FormControl>
            </CardBody>
            <CardFooter>
              <Button disabled>Update details</Button>
            </CardFooter>
          </Card>
        </GridItem>
      </GridContainer>
    </Container>
    </div>
  );
}
